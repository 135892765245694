/**
 * zh-cn
 * @author wwl
 * @created 2023/11/6
 * @modified 2023/11/6
 */
export default {
  // 通用模块
  common: {
    site_title: '展会网',
    more: '更多',
    packUp: '收起',
    view: '查看',
    internation: '国际',
    china: '国内',
    industry: '行业',
    fair: '展会',
    concurrentFair: '同期展会',
  },

  innerChain: {
    hotArea: '热门地区',
    hotIndustry: '热门行业',
    hotExhibition: '热门展会',
  },
  // 菜单
  menu: {
    home: '首页',
    domestic: '国内展会',
    international: '国际展会',
    exhibitionInfo: '展会资讯',
    conventionCenter: '会展中心',
    cooperate: '展会合作',
    visa: '签证',
    keywordPlaceholder1: '请输入关键字',
    keywordPlaceholder2: '搜索词长度超过 50 字数限制，请删减后重试',
    keywordPlaceholder3: '请输入汉字或字母',
    keyword_error1: '只能搜索汉字或字母',
    keyword_error2: '搜索词长度超过 50 字数限制，请删减后重试',
    top: {
      ticket: '我的门票',
      fair: '我的展会',
      industry: '我的行业',
      leaderboard: '排行榜',
      sitemap: '网站地图',
      signup: '登录/注册',
      order: '我的订单',
      quit: '退出',
    },
  },
  // 页脚
  footer: {
    about: '关于我们',
    help: '帮助中心',
    contact: '联系我们',
    highpay: '高薪诚聘',
    agreement: '服务协议',
    payment: '支付方式',
    cooperation: '展会合作',
    ICP_recordNum: 'ICP备案号:浙ICP备18015679号',
    networkSecurity: '浙公网安备 33011002013432号',

    us_collaborate: '和我们合作',
    customer: '在线客服',
    booth_consult: '展位咨询',
    viewer_consult: '观众咨询',
    work_time: '周一至周日',
    work_time2: '周一至周五',
    friendship_link: '友情链接：(互换友链请加QQ: 181208512)', // 61882907

    WeChatofficial: '微信公众号',
    WeChat_applet: '微信小程序',
    desc: '请使用手机微信扫码',
    scan_follow: '扫码关注公众号',
    scan_openApplet: '扫码打开小程序',
  },
  // 首页模块
  home: {
    top_container: {
      slogan: '全球展会展位预订、门票预订',
      inputPlaceholder: '国家，城市，行业，展会名称',
    },
    hot_city: {
      title: '热门展会城市：',
      city1: '上海',
      city2: '深圳',
      city3: '广州',
      city4: '北京',
      city5: '香港',
      city6: '德国',
      city7: '日本',
      city8: '美国',
      city1_hover: '上海展会',
      city2_hover: '深圳展会',
      city3_hover: '广州展会',
      city4_hover: '北京展会',
      city5_hover: '香港展会',
    },
    kindTitle: '展会行业分类',
    hot_recommend: {
      hot_title: '推荐热门展会',
      moreButton: '更多',
      international_hover: '国际展会',
      domestic_hover: '国内展会',
      internationalTitle: '国际展会·即将开展',
      domesticTitle: '国内展会·即将开展',
      distance: '距离',
      day: '天',
      openness: '开展中...',
      timeUpdate: '时间更新中...',
      newTrends_title: '最新动态',
      exhibitionInfo_hover: '展会资讯',
      nuwInfo_title: '最新资讯',
      newExhibition_title: '最新展会',
      hot_country_title: '热门国家',
      country_suffix3: '展览会',
      country_suffix2: '展览',
      country_suffix1: '展',
      service_1: '全球展会资源',
      service_2: '专属服务顾问',
      service_3: '一站式服务',
      service_4: '快速精准匹配',
      service_5: '覆盖99个国家',
    },
    hot_area: '热门地区',
  },

  // 固定侧边栏
  fixed_sidebar: {
    phone: '电话',
    booth: '展位咨询',
    viewer: '观众咨询',
    customer: '客服',
    online_customer: '在线客服',
    my: '我的',
    vote: '门票',
    exhibition: '展位',
    cooperate: '合作',
  },

  // 展会模块
  exhibition: {
    industryTitle: '展会行业分类',
    concurrentFair: '展会同行业展会',
    filters: {
      keyword: '关键词',
      selectedCondition: '已选条件',
      area: '区域',
      region: '地区',
      city: '城市',
      hotCity: '热门城市',
      domestic: '国内展会',
      domesticExh: '国内展会(含港澳台)',
      international: '国际展会',
      all: '全部',
      more: '更多',
      packUp: '收起',
    },
    scale: '展会规模',
    heatSort: '热度排序',
    timeSort: '时间排序',
    recommendedExhibition: '推荐展会',
    industryNews: '展会资讯',
    time: '展会时间',
    latestNews: '最新展会动态资讯',
    timeUpdate: '时间更新中...',
    openness: '开展中...',
    distance: '距离',
    day: '天',
    year: '年',
    periods: '届',
    booth: '展位',
    ticket: '门票',
    exhibitor: '会刊',
    hot: '热门',
    recommended: '推荐',
    winnow: '精选',
  },
  // 展会详情页
  exhibitionDetail: {
    scale: '规模度',
    profession: '专业度',
    evaluate: '评价度',
    spectator: '观众度',
    market: '市场度',
    exhibitionAddress: '举办地址',
    exhibitionHall: '举办展馆',
    exhibitionArea: '展览面积',
    openingHours: '开闭馆时间',
    exhibitorsNumber: '展商数量',
    exhibitorsNumberUnit: '家',
    audienceSize: '观众数量',
    audienceSizeUnit: '人',
    cycle: '举办周期',
    boothMap: '展位图',
    getBoothMap: '获取展位图',
    subsidy: '补贴',
    yes: '有',
    samePeriodExhibition: '同期举办',
    viewTime: '查看展会时间',
    viewMap: '查看地图',
    exhibitionHallNumber: '展馆号',
    travelReminder: '出行提醒',
    standardBooth: '标准展位',
    bareSpaceBooth: '光地展位',
    projectManagerQuotation: '项目经理报价',
    viewPrice: '查看价格',
    lastDay: '展会最后一天需在14:00前入场',
    ticketDescription: '购票须知',
    exhibitionHighlights: '展会要点',
    boothConsult: '展位咨询',
    exhibitorPeriodical: '展商名录/电子会刊',
    scaleRating: '规模度',
    professionalRating: '专业度',
    evaluationRating: '评价度',
    spectatorRating: '观众度',
    marketRating: '市场度',
    distance: '距离开展',
    day: '天',
    exhibitionInProgress: '开展中',
    nexttermTimeRenew: '下届时间更新中',
    boothApply: '展位申请',
    ticketReservation: '门票预订',
    freeForALimitedTime: '门票限时免费',
    tourItinerary: '展团行程',
    weChatShare: '小程序分享',
    hotelReservation: '酒店预订',
    exhibitionPicture: '展会视图',
    visaProcessing: '签证办理',
    poster: '生成海报',
    view: '浏览',
    exhibitionIntroduction: '展会介绍',
    exhibitsRange: '展品范围',
    relatedExhibition: '相关展会',
    industry: '所属行业',
    organizer: '主办单位',
    exhibitorNumber: '展商数量',
    exhibitionInvitationLetter: '招展函',
    buyerData: '买家数据',
    exhibitionHall2: '展馆',
    attend: '人有兴趣参加',
    exhibitionReminder: '展会提醒',
    warmReminder: '温馨提醒',
    warmReminderContent:
      '展会举办时间和地点具有一定变更风险，请以收到的电子门票凭证为准，聚展也将为预订门票的用户提供电话、短信通知提醒，避免用户的出行损失。',
    notificationReminder: '通知提醒',
    addReminder: '添加到我的展会提醒',
    viewReminder: '查看我的提醒',
    openNotification: '开启订阅通知',
    closeNotification: '关闭订阅通知',
    changeNotice: '展会时间变动、交通信息随手查',
    professionalAudience: '专业观众门票预订',
    electronic_journal: '展商/电子会刊查看订阅',
    reserveButton: '立即预订',
    ImmediateGet: '立即获取',
    relatedHotspotRecommendation: '相关热点推荐',
    form1: {
      companyName: '请输入公司名称(必填)',
      name: '请输入联系人姓名(必填)',
      phone: '请输入联系电话(必填)',
      email: '请输入邮箱(选填)',
      remark: '请输入备注(选填)',
      submit: '提交',
      cancel: '取消',
    },
  },
  // 展位申请页面
  boothApply: {
    nav: '展位预订/展位图获取',
    step1: '选择服务类型',
    step2: '确认公司信息',
    step3: '提交服务订单',
    step4: '客户跟进对接',
    fairTime: '展会时间',
    form: {
      input: '请输入(必填)',
      scale: '请选择展位面积(必填)',
      submit: '立即提交',
    },
    tip: {
      title: '展位申请须知',
      n1: '展位咨询提交后，工作人员将在1-2个工作日内根据预留的联系方式与您进行一对一对接',
      n1_1: '展位申请提交后，工作人员将在1 - 2个工作日内根据预留的联系方式与您进行一对一对接',
      n2: '提交公司营业执照',
      n3: '提交产品图片及名称',
      n4: '接收展会介绍文件及展位图',
      n5: '提交展位申请表/签订展位合同',
      n6: '支付合同展位订金',
      t1: '沟通需求',
      i1: '参展企业预留联系方式，项目负责人跟进沟通参展企业展位需求；',
      t2: '提交审核',
      i2: '参展企业与项目负责人确认参展意向后，提交公司名称、产品名称及简介，公司官网、联系人、联系方式等，等待主办资质审核；',
      t3: '展位报价',
      i3: '项目负责人向参展企业提供展会招展函及具体展位价格；',
      t4: '展位图',
      i4_1: '1.已出图展会，提供可选展位图给参展企业选择；',
      i4_2: '2.未出图展会，需提前2-3个月支付展位订金排队等待位置分配；',
      i4_3: '3.紧俏展会，需提前至少6个月排队等待位置分配；',
      t5: '合同签订',
      i5: '参展企业与组展单位签订展位合同，一式两份盖章生效，按合同约定对公打款;',
      t6: '展位相关服务',
      i6: '展位确定后，根据时间推进依次向参展企业收取会刊信息表、展商证、展具租赁、车证等材料；',
      t7: '发票',
      i7: '展会结束后7个工作日开具正式发票；',
      t8: '评价及意见',
      i8: '展会结束后收集参展企业对展会的评价及反馈意见，改进服务；',
    },
  },
  // 客服
  customerService: {
    boothConsultation: '展位咨询',
    exhibitionTicketConsultation: '展会门票咨询',
    boothSale: '展位销售',
    telephone: '直线',
    mobilePhone: '手机',
    wechat: '专属微信',
    onlineContact: '在线问',
    workTime: '工作时间',
  },
  // 展位价格
  booth: {
    boothPrice: '展位价格',
    standard_booth: '标准展位',
    blank_booth: '光地展位',
    configuration: '配置',
    price: '价格',
    base: '基础',
    viewPrice: '查看价格',
    boothSampleGraph: '展位示意图',
    tip: '仅供参考，以实际展位配置为准',
    viewFloorPrice: '获取底价',
    configInfo1: {
      item1: '地毯',
      item2: '一桌',
      item3: '两（三）椅',
      item4: '一个废纸篓',
      item5: '公司楣板',
      item6: '两（三）个射灯',
      item7: '两（三）面墙板',
    },
    configInfo2: {
      item1: '不含任何设施',
      item2: '遵守展馆限制',
      item3: '需最小起订面积',
    },
  },
  // 参展流程
  exhibitionProcess: {
    name: '参展流程',
    item1: '1.提交公司营业执照',
    item2: '2.提交产品图片及名称',
    item3: '3.接收展会介绍文件及展位图',
    item4: '4.提交展位申请表/签订展位合同',
    item5: '5.支付合同展位订金',
    item6: '6.准备参展',
  },
  // 用户社区
  userCommunity: {
    userCommunity: '用户社区',
  },

  // 资讯模块
  news: {
    information: '资讯',
    latestExhibition: '最新展会',
    latestNews: '最新资讯',
    notMultiple: '不支持多选',
  },

  // 会展中心模块
  pavilion: {
    name: '会展中心',
    filters: {
      region: '地区',
      all: '全部',
      china: '中国',
      nation: '国家',
      more: '更多',
      packUp: '收起',
    },
    total: '共',
    stripStadium: '条场馆',
    countryAndRegion: '国家地区',
    pavilionAddress: '展馆地址',
    pavilionArea: '展馆面积',
    squareMeter: '平方米',
    recommendedExhibition: '推荐展会',
    more: '更多',
  },

  // 登录模块
  login: {
    title: '全球展会展位预定、门票预订',
    wechatLoginOrRegister: '微信扫码登录或注册',
    phoneOrEmail: '手机/邮箱验证码登录',
    passwordLogin: '账号密码登录',
    accountHere: '账号登录在这里',
    scanCodeHere: '扫码登录在这里',
    forgotPassword: '忘记密码',
    registerNow: '立即注册',
    signup: '登录',
    register: '账号注册',
    bindAccount: '绑定账号',
    registerBtnText: '注 册',
    phonePlaceholder: '输入手机号或邮箱',
    phonePlaceholder2: '输入手机号',
    phoneError1: '请输入你的手机号或邮箱',
    phoneError2: '请输入正确的手机号或邮箱',
    phoneError3: '该手机号或邮箱未注册',
    phoneError4: '请输入你的手机号',
    phoneError5: '请输入正确的手机号',
    phoneError6: '该手机号已绑定',
    passwordPlaceholder: '输入登录密码',
    passwordError1: '请输入登录密码',
    newPasswordPlaceholder: '输入新登录密码',
    newPasswordError1: '请输入你的新密码',
    codePlaceholder: '输入验证码',
    codeError1: '请输入你的验证码',
    getVerificationCode: '获取验证码',
    getInSeconds: '秒后获取',
    resetPassword: '重置密码',
    parameterInvalid: '参数不合法',
    iHaveReadAndAgree: '我已阅读并同意',
    serviceAgreement: '第三方商品平台交易服务协议',
    serviceAgreementError: '请阅读并勾选服务协议',
    bindingLogin: '绑定登录',
    scanCodeLogin: '扫码登录',
    verificationCodeLogin: '验证码登录',
    passwordLoginText: '密码登录',
  },

  // 个人中心模块
  personal: {
    left: {
      fans: '粉丝',
      follow: '关注',
      personal_center: '个人中心',
      my_order: '我的订单',
      my_exhibition: '我的展会',
      my_vote: '我的门票',
      my_visa: '我的签证',
      my_hotel: '我的酒店',
      my_planeTicket: '我的机票',
      exhibitor_list: '展商名录',
      exhibitions_followed: '关注的展会',
      authentication: '企业认证',
      change_password: '修改密码',
    },
    right: {
      // 个人中心
      follow_industry: '关注的行业',
      add_button: '添加',
      recent: '近期展会',
      distance: '距离开展',
      day: '天',
      ln_progress: '开展中...',
      time_updating: '时间更新中......',
      modify_info: '修改个人资料',
      head_sculpture: '头像',
      prompt: '点击图片替换头像',
      user_name: '用户名',
      user_placeholder: '请输入用户名(必填)',
      gender: '性别',
      gender_placeholder: '请选择性别(必选)',
      male: '男',
      female: '女',
      corporate_name: '公司名称',
      corporate_plader: '请输入公司名称(必填)',
      main_products: '主营产品',
      main_placeholder: '请输入公司产品(必填)',
      company_website: '公司网址',
      company_plader: '请输入公司名称(选填)',
      duties: '职务',
      duties_plader: '请输入职务(必填)',
      email: '邮箱',
      email_plader: '请输入邮箱(选填)',
      confirm_button: '确认',
      modifyInfo_inputRule1: '请输入用户名.',
      modifyInfo_inputRule2: '请选择性别.',
      modifyInfo_inputRule3: '昵称不能是手机号、电话号、邮箱或包含特殊字符',
      modifyInfo_inputRule4: '请输入公司名称.',
      modifyInfo_inputRule5: '请输入公司产品',
      modifyInfo_inputRule6: '请输入职位.',
      // 我的展会
      applied_for: '我申请过的展会',
      participate: '参展公司',
      exhibit_content: '展品内容',
      booth_type: '展位类型',
      booth_area: '展位面积',
      processed: '已处理',
      pending: '待处理',
      raw_sace: '光地展位',
      standard_booth: '标准展位',
      advanced_booth: '进阶展位',
      // 我的门票
      my_vote: '我的门票',
      whole: '全部',
      tobepaid: '待支付',
      issue_tickets: '出票中',
      stop_tickets: '已出票',
      refunded: '已退款',
      // 我的签证
      my_visa: '我的签证',
      // 我的酒店
      my_hotel: '我的酒店',
      // 我的机票
      my_plane_ticket: '我的机票',
      closed: '已关闭',
      // 展商名录
      order_num: '订单编号',
      create_time: '创建时间',
      electronic_journal: '展商名录/电子会刊',
      ear_of_publication: '会刊年份',
      journal_name: '会刊名称',
      notes1:
        '备注：展商名录/电子会刊将在1-2个工作日内以电子邮件形式发送至申请人邮箱，请注意查收(如未收到请查看垃圾邮箱或联系0571-88560061)',
      notes2: '在线展商名录仅支持浏览，不发邮件，不支持复制文字等内容',
      refer_to_button: '查阅会刊',
      state_button1: '开发票',
      state_button2: '开发票中',
      state_button3: '查看发票',
      order_status1: '未支付',
      order_status2: '已申请',
      order_status3: '已支付',
      order_status4: '已完成',
      // 关注的展会
      correct_follow: '已关注',
      deny_follow: '关注',
      follow_exhibition: '关注展会',
      industry_follow: '关注行业',
      launch_time: '还有',
      launch_time_day: '天',
      open_develop: '开展中',
      nextterm_time_renew: '下届时间更新中',
      view_details: '查看详情',
      other_user_box1: '其他一些推荐',
      other_user_box2: '的用户',
      recommend_exhibition: '推荐展会',
      ETag_status1: '普通',
      ETag_status2: '延期',
      ETag_status3: '变更',
      ETag_status4: '取消',
      // 企业认证
      account_login: '账号登录:',
      auth_corporate: '公司名称:',
      your_corporate: '您的公司名称',
      english_name: '英文名称',
      your_english_name: '您的公司英文名称',
      corporate_address: '公司地址:',
      select_address: '请选择地址',
      indetail_address: '详细地址:',
      where_address: '所在公司地址',
      corporate_introduce: '公司介绍:',
      introduce: '介绍',
      corporate_phone: '公司电话',
      registered_capital: '注册资金:',
      select_capital: '请选择企业注册资金',
      capital_option1: '100万以下',
      capital_option2: '100-500万',
      capital_option3: '500万-1000万',
      capital_option4: '1000万-3000万',
      capital_option5: '3000万以上',
      staff_num: '员工人数:',
      select_staff_num: '请选择员工人数',
      staff_num_option1: '10-30人',
      staff_num_option2: '30-50人',
      staff_num_option3: '50-100人',
      staff_num_option4: '100人以上',
      service_area: '服务区域:',
      delete: '删除',
      contacts: '联系人:',
      Enter_contacts: '请输入联系人',
      mobile_phone: '手机号:',
      Enter_phone: '请输入手机号',
      Enter_qq: '请输入qq',
      WeChat: '微信:',
      Enter_WeChat: '请输入微信',
      mailbox: '邮箱:',
      Enter_mailbox: '请输入邮箱',
      position: '职位:',
      Enter_position: '请选择职位',
      position_option1: '普通员工',
      position_option2: '经理',
      position_option3: '总经理',
      position_option4: '董事长',
      id_card: '身份证:',
      business_license: '营业执照:',
      qualifications: '公司资质:',
      corporate_photos: '企业照片:',
      enterprise_video: '企业视频:',
      select_video: '选择视频',
      video_size_limit: '视频大小不能大于 10 M',
      submit_authenti: '提交认证',
      // 修改密码
      login_account: '登录账号:',
      message_code: '短信验证码:',
      new_password: '新密码:',
      submit: '提交',
      obtain_code: '获取验证码',
      code_cannot_empty: '短信验证码不能为空',
      new_psd_cannot_empty: '新密码不能为空',
      password_tooweak: '密码强度不够',
      second_obtain: '秒后获取',
      param_illegal: '参数不合法',
    },
  },
  // TDK title, description, keyword
  tdk: {
    home: {
      title: '聚展网-展会网_国际展会_展会门票_展会信息服务平台',
      keywords: '展会,博览会,展会门票,展会会刊,交易会,展会信息',
      description:
        '聚展网为您提供专业的展会信息服务,展会门票,展会会刊,展位预定,展会推广,展会行程,展会物流,展会搭建,展位设计,专业观众引流,展会信息触达全球一百多个国家',
    },
    ticket: '门票',
    time: '时间',
    magazine: '会刊',
    jufair: '聚展',
    jufair2: '聚展网',
    fairTime: '展会时间',
    address: '展会地点',
    sponsor: '主办方',
    // 展会详情页
    exhibitionDetail: {
      description: '聚展网提供展会门票和展位预订服务',
      keyword1: '展',
      keyword2: '展时间',
      keyword3: '展门票',
      keyword4: '展展位图',
      keyword5: '展价格',
    },
  },
};
